<div class="navbar-area navbar-style-two" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                        <!-- <a *ngIf="!isOrganizationURL" class="navbar-brand" routerLink="/"><img src="assets/img/logo.png"
                                        alt="On Offer"></a>
                        <marquee *ngIf="isOrganizationURL" class="col-10 col-lg-8">
                                <h3>{{resource.home_banner_heading}}</h3>
                        </marquee> -->

                        <a class="navbar-brand" routerLink="/my-dashboard"><img src="assets/img/logo.png" alt="On Offer"></a>
                        <span *ngIf="isVisbileTagLink" class="logo-heading">{{resource.home_banner_heading}}</span>
                        <button class="navbar-toggler col-1" type="button" (click)="toggleClass()">
                                <span class="burger-menu">
                                        <span class="top-bar"></span>
                                        <span class="middle-bar"></span>
                                        <span class="bottom-bar"></span>
                                </span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <!-- <form class="search-box">
                                        <label><i class='bx bx-search'></i></label>
                                        <input type="text" class="input-search"
                                                [placeholder]="resource.navbar_searchbar_placeholder">
                                </form> -->
                                <ul class="navbar-nav">
                                        <!-- <li class="nav-item"><a routerLink="/" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}"
                                                        (click)="toggleClass()"><i class='bx bx-home'></i>
                                                        {{resource.home}}</a>
                                        </li>
                                        <li class="nav-item"><a routerLink="/deal" class="nav-link"
                                                        routerLinkActive="active" (click)="toggleClass()"><i
                                                                class='bx bx-store'></i>{{resource.all_deals}}</a>
                                        </li> -->
                                        <!-- <li class="nav-item"><a class="nav-link" routerLink="/games"
                                                        routerLinkActive="active" (click)="toggleClass()">
                                                        <i class='bx bx-game'></i> {{resource.games}}</a>
                                        </li> -->

                                        <!-- <li class="nav-item" *ngIf="!isLoggedIn"><a class="nav-link" routerLink="/login"
                                                        (click)="toggleClass()"><i class='bx bx-log-in'></i> Sign In</a>
                                        </li> -->

                                        <!-- <li class="nav-item" *ngIf="!isLoggedIn"><a class="nav-link"
                                                        routerLink="/register"><i class='bx bx-user'></i>
                                                        Sign Up</a></li> -->
                                        <li class="nav-item" *ngIf="isLoggedIn">
                                                <a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"
                                                        (click)="toggleClass()"><i class='bx bx-user'></i>
                                                        {{resource.dashboard}}</a>
                                        </li>
                                        <li class="nav-item"><a routerLink="/contact" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}"
                                                        (click)="toggleClass()"><i class='bx bx-envelope'></i>
                                                        {{resource.contact_us}}</a></li>


                                </ul>
                                <!-- <div class="others-option d-flex align-items-center">
                                        <div class="option-item">
                                                <a *ngIf="isLoggedIn" routerLink="/my-dashboard/profile"
                                                        class="wishlist-btn"><i class='bx bx-user'></i></a>
                                        </div>
                                        <div class="option-item">
                                                <a routerLink="/cart" class="cart-btn"><i
                                                                class='bx bx-shopping-bag'></i></a>
                                        </div>
                                </div> -->
                        </div>
                </nav>
        </div>
</div>